/* eslint-disable camelcase */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    paramsToClear: Array,
  };

  connect() {
    this._clearParams();
  }

  _clearParams() {
    this.paramsToClearValue.forEach((param) => {
      this._removeParam(param);
    });
  }

  _removeParam(key) {
    let url = new URL(window.location.href);

    url.searchParams.delete(key);
    window.history.replaceState({}, '', url);
  }
}
