/* eslint-disable camelcase */
import { Controller } from '@hotwired/stimulus';

import JsonAdapter from '../adapters/json_adapter';

class LeaveFormDateTimeFieldController extends Controller {
  static targets = ['employeeId', 'startedAtDate', 'startedAtTime', 'endedAtDate', 'endedAtTime'];
  static values = { url: String };

  initialize() {
    this.adapter = new JsonAdapter();
  }

  change() {
    return this._fetch();
  }

  async _fetch() {
    const response = await this.adapter.get(this.urlValue, {
      employee_id: this.employeeIdTarget.value,
    });

    this._handleResponse(await response.json());
  }

  _handleResponse(response) {
    const { started_at_date, started_at_time, ended_at_date, ended_at_time } = response;

    this.startedAtDateTarget.value = started_at_date;
    this.startedAtTimeTarget.value = started_at_time;
    this.endedAtDateTarget.value = ended_at_date;
    this.endedAtTimeTarget.value = ended_at_time;
  }
}

export default LeaveFormDateTimeFieldController;
