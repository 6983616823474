import { Controller } from '@hotwired/stimulus';

const INVALID_CLASS = 'is-invalid';
const INVALID_FEEDBACK_CLASS = 'invalid-feedback';

export default class extends Controller {
  clear() {
    this._removeInvalidClasses();
    this._removeErrorMessages();
  }

  _removeInvalidClasses() {
    this.element.querySelectorAll(`.${INVALID_CLASS}`).forEach((element) => {
      element.classList.remove(INVALID_CLASS);
    });
  }

  _removeErrorMessages() {
    this.element.querySelectorAll(`.${INVALID_FEEDBACK_CLASS}`).forEach((element) => {
      element.remove();
    });
  }
}
