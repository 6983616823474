import BaseAdapter from './base_adapter';

class JsonAdapter extends BaseAdapter {
  get(url, data = {}) {
    const params = this.buildRequestParams(data);

    return fetch(`${url}?${params}`, {
      headers: { accept: 'application/json' },
    });
  }
}

export default JsonAdapter;
