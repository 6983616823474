import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hideable'];

  showTargets() {
    this.hideableTargets.forEach((element) => {
      element.hidden = false;
    });
  }

  hideTargets() {
    this.hideableTargets.forEach((element) => {
      element.hidden = true;
    });
  }

  toggleTargets() {
    this.hideableTargets.forEach((element) => {
      element.hidden = !element.hidden;
    });
  }
}
