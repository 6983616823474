/* eslint-disable camelcase */
const MONTH_OFFSETS = { previous_month: -1, current_month: 0, next_month: 1 };

const NUMBER_OF_DAYS_IN_CYCLE = 31;
const DEFAULT_NUMBER_OF_DAYS_TO_CYCLE_END_DATE = 30;

class CycleDate {
  static newFromString(cycleDate) {
    if (this.isNullOrUndefined(cycleDate)) return;

    const [monthOffsetValue, day] = cycleDate.split(',').map((value) => parseInt(value));

    if (this.validate(monthOffsetValue, day)) return new this(monthOffsetValue, day);
  }

  static getMonthOffsetValueFromKey(key) {
    return MONTH_OFFSETS[key];
  }

  static getMonthOffsetKeyFromValue(value) {
    return Object.keys(MONTH_OFFSETS).find((key) => MONTH_OFFSETS[key] === value);
  }

  static validate(monthOffsetValue, day) {
    if (!(!this.isNullOrUndefined(monthOffsetValue) && !this.isNullOrUndefined(day))) return false;
    if (!(day >= 1 && day <= 31)) return false;
    if (this.isNullOrUndefined(this.getMonthOffsetKeyFromValue(monthOffsetValue))) return false;

    return true;
  }

  static isNullOrUndefined(data) {
    return data === undefined || data === null;
  }

  constructor(monthOffsetValue, day) {
    this.monthOffsetValue = monthOffsetValue;
    this.day = day;
  }

  calculateCycleEndDate(numberOfDaysToCycleEndDate = DEFAULT_NUMBER_OF_DAYS_TO_CYCLE_END_DATE) {
    const numberOfDaysInCycle = NUMBER_OF_DAYS_IN_CYCLE;

    const cycleStartMonthOffsetValue = this.monthOffsetValue;
    const cycleStartDay = this.day;

    if (!this.constructor.validate(cycleStartMonthOffsetValue, cycleStartDay)) return;

    const cycleEndDay = cycleStartDay + numberOfDaysToCycleEndDate;

    const newCycleEndMonthOffsetValue =
      cycleStartMonthOffsetValue + Math.floor((cycleEndDay - 1) / numberOfDaysInCycle);

    const newCycleEndDay =
      cycleEndDay % numberOfDaysInCycle !== 0
        ? cycleEndDay % numberOfDaysInCycle
        : numberOfDaysInCycle;

    return new this.constructor(newCycleEndMonthOffsetValue, newCycleEndDay);
  }

  stringifiedCycleDate() {
    return `${this.monthOffsetValue},${this.day}`;
  }
}

export default CycleDate;
