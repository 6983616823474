import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['modalWrapper'];

  show() {
    if (!this.modalWrapperTarget) {
      console.error('Modal wrapper target not found.');
      return;
    }

    this.modal = new Modal(this.modalWrapperTarget);
    this.modal.show();
  }
}
