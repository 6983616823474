import { Controller } from '@hotwired/stimulus';

const ACTIONS = { update: 'update', view: 'view', delete: 'delete' };

const ROLE_PERMISSION_FEATURE_GROUPS = [
  'organizations',
  'employees',
  'working_schedules',
  'attendances',
  'overtimes',
  'leaves',
  'timesheets',
  'payrolls',
  'government_reports',
  'general_reports',
  'users',
  'settings',
];

export default class extends Controller {
  connect() {
    this._setFeatureGroupCheckboxes();
  }

  onHover(event) {
    this._toggleHoveredClass(event, true);
  }

  offHover(event) {
    this._toggleHoveredClass(event, false);
  }

  redirect(event) {
    window.location = event.target.dataset.href;
  }

  toggleCheckboxAll(event) {
    const {
      actionValue: action,
      featureGroupValue: featureGroup,
      permissionValue: permissionName,
    } = event.target.dataset;
    const isChecked = event.target.checked;

    this._toggleAllChildCheckboxes(permissionName, isChecked);

    if (action === ACTIONS.view && !isChecked) {
      this._toggleFeatureGroupCheckboxes(
        featureGroup,
        [this._capitalizeFirstLetter(ACTIONS.update), this._capitalizeFirstLetter(ACTIONS.delete)],
        isChecked
      );
    } else if ((action === ACTIONS.update || action === ACTIONS.delete) && isChecked) {
      this._toggleFeatureGroupCheckboxes(
        featureGroup,
        [this._capitalizeFirstLetter(ACTIONS.view)],
        isChecked
      );
    }
  }

  toggleCheckbox(event) {
    const {
      actionValue: action,
      featureValue: feature,
      permissionValue: permissionName,
    } = event.target.dataset;
    const isChecked = event.target.checked;

    if (action === ACTIONS.view && !isChecked) {
      this._toggleFeatureCheckboxes(feature, [ACTIONS.update, ACTIONS.delete], isChecked);
    } else if ((action === ACTIONS.update || action === ACTIONS.delete) && isChecked) {
      this._toggleFeatureCheckboxes(feature, [ACTIONS.view], isChecked);
    }

    this._setStateOfParentCheckbox(permissionName);
  }

  _toggle(feature, action, isChecked) {
    const checkbox = this._getCheckbox(action, feature);

    if (checkbox) {
      checkbox.checked = isChecked;
      this._setStateOfParentCheckbox(checkbox.dataset.permissionValue);
    }
  }

  _toggleFeatureCheckboxes(feature, actions, isChecked) {
    actions.forEach((action) => this._toggle(feature, action, isChecked));
  }

  _toggleFeatureGroupCheckboxes(featureGroup, actions, isChecked) {
    actions.forEach((action) =>
      this._toggleAllChildCheckboxes(`${featureGroup}${action}`, isChecked)
    );
  }

  _toggleAllChildCheckboxes(permissionName, isChecked) {
    const checkboxes = this._getCheckboxes(permissionName);

    checkboxes
      .filter((checkbox) => !checkbox.disabled)
      .forEach((checkbox) => {
        checkbox.checked = isChecked;
        checkbox.indeterminate = false;
      });
  }

  _toggleHoveredClass(event, isHovered) {
    const itemIndex = event.target.cellIndex;
    const hoveringItems = this._getHoveringItems(itemIndex);

    hoveringItems.forEach((item) => {
      isHovered ? item.classList.add('hovered') : item.classList.remove('hovered');
    });
  }

  _setStateOfParentCheckbox(permissionName) {
    const checkboxes = this._getCheckboxes(permissionName, true);
    const parentCheckbox = this._getParentCheckbox(permissionName);

    if (!parentCheckbox) return;

    if (checkboxes.every((checkbox) => checkbox.checked)) {
      parentCheckbox.checked = true;
      parentCheckbox.indeterminate = false;
    } else if (checkboxes.every((checkbox) => !checkbox.checked)) {
      parentCheckbox.checked = false;
      parentCheckbox.indeterminate = false;
    } else {
      parentCheckbox.indeterminate = true;
    }
  }

  _setFeatureGroupCheckboxes() {
    ROLE_PERMISSION_FEATURE_GROUPS.forEach((featureGroup) => {
      Object.values(ACTIONS).forEach((action) => {
        const permissionName = `${featureGroup}${this._capitalizeFirstLetter(action)}`;

        this._setStateOfParentCheckbox(permissionName);
      });
    });
  }

  _getCheckbox(action, feature) {
    return document.querySelector(
      `input[data-action-value="${action}"][data-feature-value="${feature}"]`
    );
  }

  _getCheckboxes(permissionName, ignoreParent = false) {
    let selector = `input[data-permission-value="${permissionName}"]`;

    if (ignoreParent) {
      selector += `:not(#${permissionName})`;
    }

    return Array.from(document.querySelectorAll(selector));
  }

  _getHoveringItems(itemIndex) {
    return this.element.querySelectorAll(
      `td:nth-child(${itemIndex + 1}), th:nth-child(${itemIndex + 1})`
    );
  }

  _getParentCheckbox(permissionName) {
    return document.querySelector(`#${permissionName}`);
  }

  _capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
