import { Controller } from '@hotwired/stimulus';

import JsonAdapter from '../adapters/json_adapter';
import SelectHelper from '../helpers/select_helper';

/**
 * Controls the value of a field (text or select) based on the value of multiple elements
 *
 * Examples of use case:
 *   - A form with selects for all divisions (company, branch, function, department, section, and team).
 *     The team select only displays teams that belong to all the previous divisions.
 *   - A form with Payroll params (company, period and cycle). The field `pay_date` can use all these params
 *     and its value it calculated on the server side and returned as a JSON response
 *     (see the `PayrollPaydates#show` action)
 *
 * NOTE: Depending on what needs to be refreshed, consider using
 * TurboFrameIndexController/TurboFrameShowController and turbo frames instead
 *
 * Types of targets:
 *   1. dependentField:
 *     - MUST be a select or input element.
 *       Its value depends on the controlledParam targets' value.
 *     - MUST have the `data-current-data-path` attribute (GET url to fetch)
 *     - can have a `data-current-value` attribute
 *   2. controlledParam: when the action `dependent-fetch#change` is triggered, a param will be created
 *      for each of these targets, using the element's `{name} => {value}` as key and value.
 *      To make requests, the action 'dependent-fetch#change' must be registered on each param target.
 *
 * An optional value `toggleDependentField` allows to toggle the visibility of the dependent field (hidden by default).
 *
 * @example
 *
 * <div data-controller="dependent-fetch">
 *   <input name="employment_type"
 *     data-dependent-fetch-target="controlledParam"
 *     data-action="dependent-fetch#change"
 *   />
 *   <input name="period"
 *     data-dependent-fetch-target="controlledParam"
 *     data-action="dependent-fetch#change"
 *   />
 *
 *   <input name="pay_date"
 *     data-dependent-select-target="dependentField"
 *     data-current-data-path="/payroll_paydate"
 *     current_value="01/01/2023"
 *   />
 * </div>
 *
 * In the above example, when users change the value of `employment_type` or `period`, the controller will make a GET request
 * to `/payroll_paydate?employment_type=monthly&period=1/2023`. The response will be used to update the branch select accordingly
 */
class DependentFetchController extends Controller {
  static targets = ['controlledField', 'controlledParam', 'dependentField'];
  static values = { toggleDependentField: Boolean };

  initialize() {
    this.adapter = new JsonAdapter();
  }

  connect() {
    if (this.toggleDependentFieldValue && this.dependentFieldTarget) {
      this.dependentFieldTarget.hidden = true;
    }

    this.change();
  }

  change() {
    if (!this._valid()) return;

    if (this.dependentFieldTarget.type === 'select-one') {
      SelectHelper.clearSelectOptions(this.dependentFieldTarget);
    }

    if (
      this._areAllFieldsPresent(this.controlledFieldTargets) &&
      this._areAllFieldsPresent(this.controlledParamTargets)
    ) {
      return this._fetch();
    }
  }

  // Known issue: multiple AJAX requests are triggered and the response might not be parsed in the right order. Could be improved by aborting the previous request before triggering a new one. This will be done in the chore sc-73317.
  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#aborting_a_fetch
  async _fetch() {
    const response = await this.adapter.get(this._buildUrl(), this._buildParams());

    this._handleResponse(await response.json());
  }

  _valid() {
    return this.hasDependentFieldTarget;
  }

  _buildUrl() {
    let url = this.dependentFieldTarget.dataset.currentDataPath;

    this.controlledFieldTargets.forEach((target) => {
      let value = target.value || target.dataset.currentValue;
      let memberReplacement = target.dataset.dependentFetchMemberReplacement;

      if (memberReplacement) url = url.replace(memberReplacement, value);
    });

    return url;
  }

  _buildParams() {
    return this.controlledParamTargets.reduce((params, element) => {
      const isCheckbox =
        element?.tagName?.toLowerCase() === 'input' && element?.getAttribute('type') === 'checkbox';

      params[element.name] = isCheckbox ? element.checked : element.value;

      return params;
    }, {});
  }

  _areAllFieldsPresent(fields) {
    return fields.every(
      ({ value, dataset }) =>
        Boolean(value) || Boolean(dataset.currentValue) || dataset.optionalParams
    );
  }

  _handleResponse(response) {
    if (this.toggleDependentFieldValue) {
      this.dependentFieldTarget.hidden = false;
    }

    if (this.dependentFieldTarget.dataset.dependentFetchField) {
      this.dependentFieldTarget.value =
        response[this.dependentFieldTarget.dataset.dependentFetchField];
    }

    this.dependentFieldTarget.querySelectorAll('[data-dependent-fetch-field]').forEach((child) => {
      const dataset = child.dataset;
      let fieldValue = response[dataset.dependentFetchField];
      let textContent = this._translateDayField(fieldValue, dataset.isDayField);

      child.textContent = textContent;
    });

    if (this.dependentFieldTarget.type === 'select-one') {
      // TODO: We can remove this line when the chore sc-73317 is completed.
      SelectHelper.clearSelectOptions(this.dependentFieldTarget);
      SelectHelper.buildSelectOptions(this.dependentFieldTarget, response);
    }
  }

  _translateDayField(value, isDayField) {
    if (!isDayField) return value;

    return I18n.t('time.units.days', { count: Number(value) }).toLowerCase();
  }
}

export default DependentFetchController;
