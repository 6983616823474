import { Controller } from '@hotwired/stimulus';

const ADD_STEP_BUTTON_SELECTOR = '.entitlement-step__btn-add';
const AND_ABOVE_STEP_OPTION = -1;
const MAX_YEAR_OPTION = 10;

class LeavePolicyStepFormController extends Controller {
  static targets = [
    'endsOnYearHiddenInput',
    'endsOnYearVisibleSelect',
    'row',
    'startsFromYearInput',
  ];

  connect() {
    if (this.rowTarget.dataset.newRecord === 'true') {
      this._setYearValues();
      this._disablePreviousStepEndsOnYear();
    }

    if (this.endsOnYearHiddenInputValue === AND_ABOVE_STEP_OPTION) {
      this._hideAddStepButton();
    }
  }

  onEndsOnYearChange(event) {
    const selectedEndsOnYear = parseInt(event.target.value);

    this._toggleAddStepButton(this._isAndAboveStepSelected());

    this.endsOnYearHiddenInputTarget.value = selectedEndsOnYear;
  }

  showAddStepButton() {
    this._addStepButton().hidden = false;
  }

  _isAndAboveStepSelected() {
    return this.endsOnYearVisibleSelectValue === AND_ABOVE_STEP_OPTION;
  }

  _toggleAddStepButton(isAndAboveSelected) {
    isAndAboveSelected ? this._hideAddStepButton() : this.showAddStepButton();
  }

  _hideAddStepButton() {
    this._addStepButton().hidden = true;
  }

  _addStepButton() {
    return document.querySelector(ADD_STEP_BUTTON_SELECTOR);
  }

  _setYearValues() {
    if (this.endsOnYearHiddenInputValue !== 0) {
      return;
    }

    const previousEndsOnValue = this._getPreviousStepEndsOnYear();
    const previousEndsOnValueText = I18n.t('time.units.years', {
      count: previousEndsOnValue,
    }).toLowerCase();
    const nextYearValue = previousEndsOnValue + 1;

    this.startsFromYearInputTarget.value = previousEndsOnValueText;

    if (nextYearValue <= MAX_YEAR_OPTION) {
      this.endsOnYearVisibleSelectTarget.options[nextYearValue].selected = true;
      this.endsOnYearHiddenInputTarget.value = nextYearValue;
    } else {
      this.endsOnYearVisibleSelectTarget.options[0].selected = true;
      this.endsOnYearHiddenInputTarget.value = AND_ABOVE_STEP_OPTION;
    }
  }

  _disablePreviousStepEndsOnYear() {
    const previousSelectElement = this._getPreviousSelectElement();

    if (previousSelectElement) {
      previousSelectElement.disabled = true;
    }
  }

  _getPreviousStepEndsOnYear() {
    const previousSelectElement = this._getPreviousSelectElement();

    if (previousSelectElement) {
      return parseInt(previousSelectElement.value);
    }

    return 0;
  }

  _getPreviousSelectElement() {
    if (this.rowTarget.previousElementSibling) {
      return this.rowTarget.previousElementSibling.querySelector('select');
    }
  }

  get startsFromYearInputValue() {
    return parseInt(this.startsFromYearInputTarget.value);
  }

  get endsOnYearHiddenInputValue() {
    return parseInt(this.endsOnYearHiddenInputTarget.value);
  }

  get endsOnYearVisibleSelectValue() {
    return parseInt(this.endsOnYearVisibleSelectTarget.value);
  }
}

export default LeavePolicyStepFormController;
