import { Controller } from '@hotwired/stimulus';

class EmployeeProvidentFundSettingController extends Controller {
  static targets = ['isEnabled'];

  connect() {
    this.setEnabledState();
  }

  setEnabledState() {
    const isEnabled = this.isEnabledTarget.checked;

    // Filter out hidden inputs, checkboxes, and submit buttons, inputs that are nested inside hidden elements
    const availableInputs = Array.from(
      this.element.querySelectorAll(
        'input:not([type="checkbox"], [type="submit"], [type="hidden"]), select'
      )
    ).filter((input) => {
      let element = input;
      while (element && element !== this.element) {
        element = element.parentNode;
      }
      return true;
    });

    availableInputs.forEach((input) => {
      if (input.getAttribute('data-disabled') === 'disabled') {
        input.disabled = true;
      } else {
        input.disabled = !isEnabled;
      }
    });
  }
}

export default EmployeeProvidentFundSettingController;
