/* eslint-disable camelcase */
import { Controller } from '@hotwired/stimulus';

import JsonAdapter from '../adapters/json_adapter';

const ROLES = {
  applicationAdmin: 'application_admin',
  employee: 'employee',
};

export default class extends Controller {
  static values = {
    role: String,
  };

  static targets = [
    'emailLabelSubtext',
    'formEmail',
    'formEmployeeProfile',
    'selectEmployeeProfile',
    'selectRole',
    'userEmail',
  ];

  initialize() {
    this.adapter = new JsonAdapter();
  }

  connect() {
    this.handleRoleSelection();
  }

  handleRoleSelection() {
    if (this.roleValue === ROLES.applicationAdmin) {
      this._toggleEmailField();
    } else {
      this._toggleEmployeeProfile();
      this._toggleEmailField();
      this._toggleEmployeeProfileRequiredClass();
      this._toggleEmailLabelSubtext();
    }
  }

  async associateEmployeeProfile() {
    const employeeProfileId = this.selectEmployeeProfileTarget.value;

    this._toggleEmailLabelSubtext();

    if (!employeeProfileId) return;

    const response = await this._fetchEmployee(employeeProfileId);
    const workEmail = response.work_email;

    if (!workEmail) return;

    this._setEmail(workEmail);
  }

  async _fetchEmployee(employeeProfileId) {
    const endpoint = `/employees/${employeeProfileId}`;
    const response = await this.adapter.get(endpoint);

    return response.json();
  }

  _setEmail(email) {
    this.userEmailTarget.value = email;
  }

  _toggleEmailLabelSubtext() {
    const isEmployeeProfileSelected = this.selectEmployeeProfileTarget.value !== '';

    this.emailLabelSubtextTarget.hidden = !isEmployeeProfileSelected;
  }

  _toggleEmployeeProfileRequiredClass() {
    const isEmployee = this.selectRoleTarget.value === ROLES.employee;
    const labelClassList = this.formEmployeeProfileTarget.querySelector('label').classList;
    const requiredClassname = 'form__label--required';

    isEmployee ? labelClassList.add(requiredClassname) : labelClassList.remove(requiredClassname);
  }

  _toggleEmployeeProfile() {
    this.formEmployeeProfileTarget.hidden = this._isRoleEmpty();
  }

  _toggleEmailField() {
    this.formEmailTarget.hidden = this._isRoleEmpty();
  }

  _isRoleEmpty() {
    return this.selectRoleTarget.value === '';
  }
}
