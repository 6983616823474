import { Controller } from '@hotwired/stimulus';

import JsonAdapter from '../adapters/json_adapter';
import SelectHelper from '../helpers/select_helper';

/**
 * Controls the options list of a select element based on the value of another element
 *
 * Example use case: a form with only a company select and a branch select. The branch select
 * only displays branches that belong to the selected company.
 *
 * NOTE: If your element depends on multiple other elements, consider using
 * DependentFetchController or TurboFrameIndexController/TurboFrameShowController and turbo frames instead
 *
 * Types of targets:
 *   1. dependentSelect: this target MUST be a select element. Its options list depends on the
 *     controlledBySelect target value.
 *   2. controlledBySelect: when the value of this target changes, it will request a new options list
 *     from the server, then update them to dependentSelect target.
 *
 * To make requests, the controller needs these components:
 *   1. data-options-data-path: GET request's path. This belongs to dependentSelect target
 *   2. data-dependent-select-name-value: query string key. This is a controller value
 *   3. value of the controlledBySelect target: query string value
 *
 * @example
 *
 * <div data-controller="dependent-select" data-dependent-select-name-value="company_id">
 *   <select
 *     id="company-select"
 *     data-dependent-select-target="controlledBySelect"
 *     data-action="dependent-select#change"
 *   >
 *    <option value="1">Company 1</option>
 *    <option value="2">Company 2</option>
 *   </select>
 *
 *   <select
 *     id="branch-select"
 *     data-dependent-select-target="dependentSelect"
 *     data-options-data-path="/branches"
 *   >
 *   </select>
 * </div>
 *
 * In the above example, when users select Company 1, the controller will make a GET request
 * to `/branches?company_id=1`. The response will be used to update the branch select accordingly
 */
class DependentSelectController extends Controller {
  static targets = ['controlledBySelect', 'dependentSelect'];
  static values = { name: String, dependentContentKey: String };

  initialize() {
    this.adapter = new JsonAdapter();
  }

  connect() {
    if (this.controlledBySelectTarget.value) {
      this.change();
    }
  }

  change() {
    this.dependentSelectTargets.forEach((dependentSelect) => {
      this._changeSelect(dependentSelect);
    });
  }

  _changeSelect(dependentSelect) {
    SelectHelper.clearSelectOptions(dependentSelect);

    if (this.controlledBySelectTarget.value) {
      this._fetch(dependentSelect);
    }
  }

  async _fetch(dependentSelect) {
    const optionsPath = dependentSelect.dataset.optionsDataPath;
    const params = this._buildRequestParams();

    const response = await this.adapter.get(optionsPath, params);
    const items = await response.json();

    SelectHelper.buildSelectOptions(dependentSelect, items);
  }

  _buildRequestParams() {
    const params = {};
    params['per_page'] = 'all';
    params[this.nameValue] = this.controlledBySelectTarget.value;

    return params;
  }
}

export default DependentSelectController;
