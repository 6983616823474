import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  show() {
    console.log('Show');
    let modalElement = document.getElementById('timesheetModal');
    let modal = new Modal(modalElement, {
      backdrop: true,
      keyboard: true,
    });
    modal.show();
  }
}
