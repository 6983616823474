import { Controller } from '@hotwired/stimulus';

const INVALID_FEEDBACK_SELECTOR = '.invalid-feedback';
const FORM_CONTROL_SELECTOR = '.form__control';
const FORM_SELECT_SELECTOR = '.form__select';

export default class extends Controller {
  connect() {
    const invalidFeedback = document.querySelector(INVALID_FEEDBACK_SELECTOR);

    if (!invalidFeedback) return;

    const errorGroup = invalidFeedback.parentElement;

    this._scrollToElement(errorGroup);
    this._focusOnElement(errorGroup);
  }

  _focusOnElement(errorGroup) {
    const element =
      errorGroup.querySelector(FORM_CONTROL_SELECTOR) ||
      errorGroup.querySelector(FORM_SELECT_SELECTOR);

    if (!element) return;

    this.scrollListener = () => this._focusAfterScroll(element);
    addEventListener('scroll', this.scrollListener);
  }

  _focusAfterScroll(element) {
    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      element.focus();

      removeEventListener('scroll', this.scrollListener);
    }, 100);
  }

  _scrollToElement(errorGroup) {
    setTimeout(() => {
      window.scrollTo({
        top: Math.abs(errorGroup.getBoundingClientRect().top),
        behavior: 'smooth',
      });
    }, 0);
  }
}
