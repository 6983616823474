import { Controller } from '@hotwired/stimulus';

/**
 * Controls the visibility of an element based on the value of another element
 *
 * Example use cases:
 *   1. Show additional fields when users select a specific option from a select element
 *   2. Show/hide fields when users check/uncheck a checkbox
 *
 * Types of targets:
 *   1. hideable: these targets will be hidden/shown based on the value of the controlledBy target. To specify
 *     which values would show this target, use data-dependent-visibility-show-values attribute. This
 *     is a comma-separated string (i.e. data-dependent-visibility-show-values="fixed,progressive")
 *   2. controlledBy: this target value will determine the visibility of hideable targets. Usually, you will trigger
 *     change action when the value of this target changes
 *
 * @example
 *
 * <div data-controller="dependent-visibility">
 *   <input data-dependent-visibility-target="controlledBy" data-action="dependent-visibility#change"/>
 *   <p data-dependent-visibility-target="hideable" data-dependent-visibility-show-values="bingo,jackpot">
 *     Your current input is either bingo or jackpot
 *   </p>
 * </div>
 */
class DependentVisibilityController extends Controller {
  static targets = ['hideable', 'controlledBy'];

  connect() {
    this._toggleVisibility(this.controlledByTarget.value);
  }

  change(event) {
    this._toggleVisibility(event.target.value);
  }

  _toggleVisibility(value) {
    this.hideableTargets.forEach((hideableTarget) => {
      const showValues = hideableTarget.dataset.dependentVisibilityShowValues.split(',');

      hideableTarget.hidden = !showValues.includes(value);
    });
  }
}

export default DependentVisibilityController;
