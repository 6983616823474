'use strict';

class BaseAdapter {
  get(url, data) {
    return fetch(url, {
      body: data,
    });
  }

  buildRequestParams(data) {
    let params = new URLSearchParams();

    Object.keys(data).forEach((key) => {
      params.append(key, data[key]);
    });

    return params;
  }
}

export default BaseAdapter;
