import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    path: String,
  };

  initialize() {
    this.form = this.element;
  }

  preview() {
    if (this.form.checkValidity()) {
      const originalAction = this.form.action;

      this.form.action = this.pathValue;
      this.form.requestSubmit();
      this.form.action = originalAction;
    }
  }
}
