import { Controller } from '@hotwired/stimulus';

class TurboFrameIndexController extends Controller {
  static targets = ['controlledByField', 'turboFrameResult'];
  static values = {
    allRequired: Boolean,
  };

  connect() {
    if (this.hasControlledByFieldTarget && this.controlledByFieldTarget.value) {
      this.change();
    }
  }

  change() {
    if (this.allRequiredValue && !this._allFieldsHaveValue()) return;

    this.turboFrameResultTarget.src = this._buildResourceUrl();
  }

  _buildResourceUrl() {
    const url = new URL(this.turboFrameResultTarget.dataset.resourcePath, document.baseURI);
    const params = this._appendSearchParams(url.searchParams);

    return `${url.pathname}?${params}`;
  }

  _appendSearchParams(params) {
    this.controlledByFieldTargets.forEach((target) => {
      if (target.dataset.turboFrameIndexParam) {
        params.append(target.dataset.turboFrameIndexParam, target.value);
      }
    });

    return params;
  }

  _allFieldsHaveValue() {
    return this.controlledByFieldTargets.every((target) => {
      return target.value !== null && target.value !== undefined && target.value !== '';
    });
  }
}

export default TurboFrameIndexController;
