import { Controller } from '@hotwired/stimulus';
import { Datepicker } from 'vanillajs-datepicker';
import th from 'vanillajs-datepicker/locales/th'; // eslint-disable-line import/no-unresolved

Object.assign(Datepicker.locales, th);

class DatePickerController extends Controller {
  static values = {
    format: String,
  };

  connect() {
    new Datepicker(this.element, this.datePickerOptions());
  }

  datePickerOptions() {
    const language = document.documentElement.lang;

    let baseOptions = {
      buttonClass: 'btn',
      language,
      prevArrow: this.getArrowIcon('chevron-left'),
      nextArrow: this.getArrowIcon('chevron-right'),
      orientation: 'left auto',
      format: 'dd/mm/yyyy',
    };

    if (this.formatValue === 'month') {
      baseOptions = { ...baseOptions, format: 'mm/yyyy', pickLevel: 1 };
    } else if (this.formatValue === 'year') {
      baseOptions = { ...baseOptions, format: 'yyyy', pickLevel: 2 };
    }

    return baseOptions;
  }

  getArrowIcon(iconId) {
    return `<svg class="icon"><use xlink:href="#icon-${iconId}"></use></svg>`;
  }

  submitForm() {
    this.element.form.submit();
  }
}

export default DatePickerController;
