import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['showElement', 'hideElement', 'source'];

  connect() {
    if (this.hasSourceTarget) return this.toggle();

    console.error('The ToggleController must have at least 1 source target.');
  }

  toggle() {
    const checked = this.sourceTarget.checked;

    this.showElementTargets.forEach((element) => (element.hidden = !checked));
    this.hideElementTargets.forEach((element) => (element.hidden = checked));
  }
}
