import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';

class ChoicesController extends Controller {
  static targets = ['select', 'searchPlaceholder'];
  static values = { searchPlaceholder: String };

  static searchableOptions = {
    allowHTML: false,
    itemSelectText: '',
    position: 'bottom',
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 6,
    searchFields: ['label'],
    searchPlaceholderValue: I18n.t('helpers.placeholder.search'),
    shouldSort: false,
  };

  connect() {
    if (this.selectTarget.dataset.choice === 'active') return;

    this.choice = this._initializeChoices();

    if (this.selectTarget.required) this.choice.dropdown.element.classList.add('required');
  }

  clearInput() {
    this.choice.setChoiceByValue('');
  }

  _initializeChoices() {
    ChoicesController.searchableOptions.searchPlaceholderValue = this.searchPlaceholderText;

    return new Choices(this.selectTarget, ChoicesController.searchableOptions);
  }

  get searchPlaceholderText() {
    if (this.searchPlaceholderValue === '') return I18n.t('helpers.placeholder.search');

    return this.searchPlaceholderValue;
  }
}

export default ChoicesController;
