import { Controller } from '@hotwired/stimulus';

import CycleDate from '../utils/cycle_date';

class PayrollSettingFormController extends Controller {
  static targets = [
    'payrollFirstCycleStartDate',
    'payrollFirstCycleEndDate',
    'payrollSecondCycleStartDate',
    'payrollSecondCycleEndDate',
    'employmentType',
  ];

  connect() {
    this.updateCycleEndDates();
  }

  updateCycleEndDates() {
    if (this._isDailyEmployment()) {
      const firstCycleStartDate = this.payrollFirstCycleStartDateTarget.value;
      const secondCycleStartDate = this.payrollSecondCycleStartDateTarget.value;

      const firstCycleEndDate = this._firstCycleEndDateForDailyPayment(secondCycleStartDate);
      this.payrollFirstCycleEndDateTarget.value = firstCycleEndDate;

      const secondCycleEndDate = this._secondCycleEndDateForDailyPayment(firstCycleStartDate);
      this.payrollSecondCycleEndDateTarget.value = secondCycleEndDate;
    } else {
      const firstCycleStartDate = this.payrollFirstCycleStartDateTarget.value;

      const firstCycleEndDate = this._firstCycleEndDate(firstCycleStartDate);
      this.payrollFirstCycleEndDateTarget.value = firstCycleEndDate;
    }
  }

  _firstCycleEndDate(firstCycleStartDate) {
    return CycleDate.newFromString(firstCycleStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _firstCycleEndDateForDailyPayment(secondCycleStartDate) {
    return CycleDate.newFromString(secondCycleStartDate)
      .calculateCycleEndDate(-1)
      .stringifiedCycleDate();
  }

  _secondCycleEndDateForDailyPayment(firstCycleStartDate) {
    return CycleDate.newFromString(firstCycleStartDate)
      .calculateCycleEndDate()
      .stringifiedCycleDate();
  }

  _isDailyEmployment() {
    return this.employmentTypeTarget.value === 'Daily';
  }
}

export default PayrollSettingFormController;
