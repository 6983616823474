import { Controller } from '@hotwired/stimulus';

class DependentCheckboxController extends Controller {
  static targets = ['disable', 'controlledBy'];

  connect() {
    this._toggleCheckbox(true);
  }

  changeCheckedAndDisabled() {
    this._toggleCheckbox(false);
  }

  _toggleCheckbox(useInitialStateValue) {
    this.disableTargets.forEach((disableTarget) => {
      disableTarget.checked = useInitialStateValue
        ? disableTarget.dataset.currentValue === 'true'
        : false;
      disableTarget.disabled = !this.controlledByTarget.checked;
    });
  }
}

export default DependentCheckboxController;
