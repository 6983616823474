import { Controller } from '@hotwired/stimulus';

class TurboFrameShowController extends Controller {
  static targets = ['controlledByField', 'turboFrameResult'];

  connect() {
    if (this.controlledByFieldTarget.value) {
      this.change();
    }
  }

  change(event) {
    if (!event) return;

    this.turboFrameResultTarget.src = this._buildResourceUrl();
  }

  _buildResourceUrl() {
    const url = this.turboFrameResultTarget.dataset.resourcePath;

    if (this.controlledByFieldTarget.value) {
      return `${url}/${this.controlledByFieldTarget.value}`;
    }

    this.turboFrameResultTarget.replaceChildren();
  }
}

export default TurboFrameShowController;
