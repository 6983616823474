class SelectHelper {
  static clearSelectOptions(selectElement) {
    let placeholderIsAtRootIndex = false;
    while (selectElement.options.length > 1) {
      if (selectElement.options[0].value === '') {
        placeholderIsAtRootIndex = true;
      }
      placeholderIsAtRootIndex ? selectElement.remove(1) : selectElement.remove(0);
    }
  }

  static buildSelectOptions(selectElement, jsonOptions) {
    const selectedId = selectElement.dataset.currentValue;

    jsonOptions.forEach((item) => {
      const selectOption = this.generateOption(item, selectedId);
      selectElement.appendChild(selectOption);
    });

    selectElement.dispatchEvent(new Event('change'));
  }

  static generateOption(item, selectedId) {
    const optionElement = document.createElement('option');
    optionElement.value = item.id;
    optionElement.innerHTML = item.name;
    optionElement.selected = item.id === selectedId;

    if (this.dependentContentKeyValue) {
      optionElement.dataset.textContent = item[this.dependentContentKeyValue];
    }

    return optionElement;
  }
}

export default SelectHelper;
