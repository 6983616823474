import { Controller } from '@hotwired/stimulus';

class ImageUploaderController extends Controller {
  static targets = ['fileInput', 'imagePreview'];

  preview(event) {
    if (event.target.files.length === 0) return;

    const [file] = event.target.files;
    const reader = new FileReader(); // eslint-disable-line no-undef

    reader.onload = (e) => {
      if (this.imagePreviewTarget.tagName === 'IMG') {
        this.imagePreviewTarget.src = e.target.result;
      } else {
        this.imagePreviewTarget.style.backgroundImage = `url(${e.target.result})`;
      }
    };

    reader.readAsDataURL(file);
  }

  openFileInput() {
    this.fileInputTarget.click();
  }
}

export default ImageUploaderController;
