import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static targets = ['index'];

  connect() {
    super.connect();

    this.index =
      this.indexTargets.length === 0
        ? 0
        : parseInt(this.indexTargets[this.indexTargets.length - 1].innerHTML);

    if (this.index === 0) {
      this.add(new Event('connect'));
    }
  }

  add(event) {
    super.add(event);

    this.indexTargets[this.indexTargets.length - 1].innerHTML = ++this.index;
  }

  remove(event) {
    event.preventDefault();
    this.index--;

    if (document.querySelectorAll(this.wrapperSelectorValue).length <= 1) {
      return;
    }

    const wrapper = event.target.closest(this.wrapperSelectorValue);
    const previousSelect = wrapper.previousElementSibling.querySelector(
      '.form__select-ends-on-year'
    );

    wrapper.remove();
    previousSelect.removeAttribute('disabled');
  }
}
