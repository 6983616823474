import { Controller } from '@hotwired/stimulus';

const ACCORDION_COLLAPSE_SELECTOR = '.accordion__collapse';

export default class extends Controller {
  submit() {
    if (!this._isFormValid()) {
      this._openAllAccordionItems();
    }
  }

  _isFormValid() {
    return this.element.checkValidity();
  }

  _openAllAccordionItems() {
    const accordionCollapseElements = this.element.querySelectorAll(ACCORDION_COLLAPSE_SELECTOR);
    accordionCollapseElements.forEach((element) => {
      element.classList.add('show');
    });
  }
}
