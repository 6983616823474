import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { Modal } from 'bootstrap';

export default class extends Controller {
  initialize() {
    this.dismissHref = this.element.dataset.dismissHref;
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  dismiss() {
    if (this.dismissHref) {
      Turbo.visit(this.dismissHref);
    }

    this.modal.dispose();
    this.element.remove();
  }
}
