import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    this.modal = new Modal(this.element);
  }

  hide() {
    const form = this.formTarget;
    if (form.checkValidity() === false) {
      // to show browser's validation messages
      form.reportValidity();
      return;
    }
    form.submit();
    this.modal.hide();
  }
}
