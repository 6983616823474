import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  initialize() {
    this.href = this.element.dataset.href;
  }

  navigate() {
    Turbo.visit(this.href);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
