import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['showElement', 'disableElement', 'source'];

  connect() {
    this.show();
    this.disable();
  }

  disable(event) {
    if (!this.hasSourceTarget) return;

    for (let element of this.disableElementTargets) {
      const value = event ? event.target.value : this.sourceTargetValue;

      element.disabled = !this._shouldToggle(element.dataset.toggleValues, value);
    }
  }

  show(event) {
    if (!this.hasSourceTarget) return;

    for (let element of this.showElementTargets) {
      const value = event ? event.target.value : this.sourceTargetValue;

      element.hidden = !this._shouldToggle(element.dataset.toggleValues, value);
    }
  }

  get sourceTargetValue() {
    if (this._isCheckBoxOrRadioButton()) {
      return this.sourceTarget.checked ? this.sourceTarget.value : null;
    }

    return this.sourceTarget.value;
  }

  _shouldToggle(values, value) {
    if (!values) return false;
    if (this._isCheckBoxOrRadioButton() && !value) return true;

    const toggleValues = values.split(',');

    return toggleValues.indexOf(value) !== -1;
  }

  _isCheckBoxOrRadioButton() {
    return this.sourceTarget.type === 'checkbox' || this.sourceTarget.type === 'radio';
  }
}
