import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectAll', 'child'];

  childTargetConnected(target) {
    const { parentSelector, childSelector } = target.dataset;

    if (parentSelector && childSelector) {
      const parentCheckbox = this.parentCheckbox(parentSelector);
      const childCheckboxes = this.childCheckboxes(childSelector);

      this.toggleParentState(parentCheckbox, childCheckboxes);
    }

    this.toggleSelectAll();
  }

  toggleSelectAll() {
    this.toggleParentState(this.selectAllTarget, this.childTargets);
  }

  toggleParent(event) {
    const { parentSelector, childSelector } = event.target.dataset;

    const parentCheckbox = this.parentCheckbox(parentSelector);
    const childCheckboxes = this.childCheckboxes(childSelector);

    this.toggleParentState(parentCheckbox, childCheckboxes);
  }

  toggleImmediateChildren(event) {
    const element = event.target;
    const childCheckboxes = this.childCheckboxes(element.dataset.immediateChild);

    childCheckboxes.forEach((checkbox) => {
      checkbox.checked = element.checked;
    });
  }

  toggleChildren(event) {
    this.childTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
      checkbox.indeterminate = false;
    });
  }

  toggleParentState(parentCheckbox, childCheckboxes) {
    if (!parentCheckbox) return;

    const allChildrenChecked = [...childCheckboxes].every((checkbox) => checkbox.checked);
    if (allChildrenChecked) {
      parentCheckbox.checked = true;
      parentCheckbox.indeterminate = false;
      return;
    }

    const allChildrenUnchecked = [...childCheckboxes].every((checkbox) => !checkbox.checked);
    if (allChildrenUnchecked) {
      parentCheckbox.checked = false;
      parentCheckbox.indeterminate = false;
      return;
    }

    parentCheckbox.indeterminate = true;
  }

  parentCheckbox(parentSelector) {
    return this.element.querySelector(`${parentSelector} input[type='checkbox']`);
  }

  childCheckboxes(childSelector) {
    return this.element.querySelectorAll(`${childSelector} input[type='checkbox']`);
  }
}
