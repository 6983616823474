import { Controller } from '@hotwired/stimulus';

class PayrollReportPresetFormController extends Controller {
  static targets = ['groupByField'];

  connect() {
    this.toggleGroupByFields();
  }

  toggleGroupByFields() {
    const elements = this.groupByFieldTargets;

    elements.forEach((element) => {
      this._enableSelectField(element);
      this._enableAllOptionsInSelectField(element);
    });

    while (elements.length > 0) {
      const currentElement = elements.shift();

      elements.forEach((element) => {
        if (currentElement.value === '') {
          this._deselectAnyOptionsInSelectField(element);
          this._disableSelectField(element);
        } else {
          this._deselectSpecificOptionInSelectField(element, currentElement.value);
          this._disableSpecificOptionInSelectField(element, currentElement.value);
        }
      });
    }
  }

  _disableSelectField(target) {
    target.disabled = true;
  }

  _enableSelectField(target) {
    target.disabled = false;
  }

  _disableSpecificOptionInSelectField(target, optionValueToBeDisabled) {
    const specificOption = Array.from(target).find(
      (option) => option.value !== '' && option.value === optionValueToBeDisabled
    );
    if (specificOption) {
      specificOption.disabled = true;
    }
  }

  _enableAllOptionsInSelectField(target) {
    Array.from(target).forEach((option) => {
      if (option.value !== '') {
        option.disabled = false;
      }
    });
  }

  _deselectSpecificOptionInSelectField(target, optionValueToBeDeselected) {
    if (target.value === optionValueToBeDeselected) {
      target.selectedIndex = null;
    }
  }

  _deselectAnyOptionsInSelectField(target) {
    target.selectedIndex = null;
  }
}

export default PayrollReportPresetFormController;
