import { Controller } from '@hotwired/stimulus';

class DependentTextContentController extends Controller {
  static targets = ['textContent', 'select'];
  static values = {
    defaultTextContent: String,
  };

  connect() {
    this._updateSelect();
  }

  updateTextContent() {
    this._updateSelect();
  }

  _updateSelect() {
    const value =
      this.selectTarget.selectedOptions[0].dataset.textContent || this.defaultTextContentValue;

    if (this.textContentTarget.tagName.toLowerCase() === 'input') {
      this.textContentTarget.value = value;
    } else {
      this.textContentTarget.textContent = value;
    }
  }
}

export default DependentTextContentController;
