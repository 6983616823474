import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['updatable'];

  updateTargets(event) {
    this.updatableTargets.forEach((element) => {
      element.value = event.target.dataset.newValue;
    });
  }
}
