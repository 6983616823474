import { Controller } from '@hotwired/stimulus';

class FormPersistableController extends Controller {
  static targets = ['field'];
  static values = { storageKey: String };

  connect() {
    if (!this.storageKeyValue) {
      console.error('The `data-form-persistable-storage-key-value` attribute is required');
    }

    if (this.valueRetrieved) this.element.submit();
  }

  fieldTargetConnected(field) {
    field.dataset.action = 'change->form-persistable#changeValue';

    if (field.value) this._saveFieldValue(field);
    else this._setFieldValueFromLocalStorage(field);
  }

  changeValue(event) {
    this._saveFieldValue(event.target);
  }

  clearFilterValue() {
    this.fieldTargets.forEach((field) => this._resetAndRemoveValue(field));
    this.element.submit();
  }

  _saveFieldValue(field) {
    if (!field.value) return this._resetAndRemoveValue(field);

    const key = this._localStorageKey(field.name);
    localStorage.setItem(key, field.value);
  }

  _setFieldValueFromLocalStorage(field) {
    let storedValue = localStorage.getItem(this._localStorageKey(field.name));

    if (storedValue === null) return;

    field.value = storedValue;

    // Given a select input, `value` will be '' if there is NO matching options
    if (field.value) this.valueRetrieved = true;
    // Reset input to show the placeholder
    else this._resetAndRemoveValue(field);
  }

  _resetAndRemoveValue(field) {
    field.value = '';
    localStorage.removeItem(this._localStorageKey(field.name));
  }

  _localStorageKey(fieldName) {
    return `${this.storageKeyValue}.${fieldName}`;
  }
}

export default FormPersistableController;
